import clsx from 'clsx';
import React from 'react';

import Space from './Space';
import { TBannerCardBgV2Props } from '../types';

type TSpacesProps = Pick<TBannerCardBgV2Props, 'space'> & {
  hideSpaceOnMobile?: boolean;
};

const Spaces: React.FC<TSpacesProps> = props => {
  const { space, hideSpaceOnMobile } = props;

  if (!space?.length) {
    return null;
  }

  return (
    <div
      className={clsx(
        'grid-cols-1 gap-8 ltr:items-end rtl:items-start',
        hideSpaceOnMobile ? 'hidden lg:inline-grid' : 'inline-grid',
      )}
    >
      {space?.map((block, index) => (
        <Space
          key={index}
          type={block.type}
          image={block.image}
          text={block.text}
        />
      ))}
    </div>
  );
};

export default Spaces;
