import React from 'react';

import type { TBannerCardBgV2Props } from '../types';

const Description: React.FC<
  Pick<TBannerCardBgV2Props, 'description'>
> = props => {
  const { description } = props;

  if (!description) {
    return null;
  }

  return (
    <div className='pe-2 text-base leading-extra-loose text-interface-700 empty:hidden'>
      {description}
    </div>
  );
};

export default Description;
