import React from 'react';

import {
  EEyebrowSize,
  EEyebrowTheme,
  EEyebrowVariant,
  Eyebrow,
} from '../../../atoms/Eyebrow';
import { TBannerCardBgV2Props } from '../types';

const Eyebrows: React.FC<Pick<TBannerCardBgV2Props, 'eyebrows'>> = props => {
  const { eyebrows } = props;

  if (!eyebrows?.length) {
    return null;
  }

  return (
    <div className='flex flex-wrap gap-6'>
      {eyebrows.map((eyebrow, index) => (
        <Eyebrow
          key={index}
          size={EEyebrowSize.Medium}
          variant={EEyebrowVariant.TextTracking}
          theme={EEyebrowTheme.Dark}
        >
          {eyebrow}
        </Eyebrow>
      ))}
    </div>
  );
};

export default Eyebrows;
