import React from 'react';

import baseTailwindConfig from '../../../../../tailwind.config.base';
import { useBetterMediaQuery } from '../../../../hooks/useBetterMediaQuery';
import { TitlePart } from '../../../atoms/TitlePart';
import {
  ETitlePartsPosition,
  ETitlePartsSize,
  ETitlePartsTheme,
} from '../../../atoms/TitlePart/TitlePart.constants';

import type { TBannerCardBgV2Props } from '../types';

type TTitleProps = Pick<TBannerCardBgV2Props, 'title'> & {
  shrink?: boolean;
};

const getSize = (
  isXl: boolean | undefined,
  isMd: boolean | undefined,
  shrink?: boolean,
): ETitlePartsSize => {
  if (shrink) {
    return ETitlePartsSize.Xs;
  }

  if (isXl) {
    return ETitlePartsSize.Xl;
  }

  if (isMd) {
    return ETitlePartsSize.L;
  }

  return ETitlePartsSize.Ml;
};

const Title: React.FC<TTitleProps> = props => {
  const { title, shrink } = props;

  const isXl = useBetterMediaQuery(
    `(min-width: ${baseTailwindConfig.screens.xl})`,
  );
  const isMd = useBetterMediaQuery(
    `(min-width: ${baseTailwindConfig.screens.md})`,
  );

  if (!title) {
    return null;
  }

  return (
    <TitlePart
      theme={ETitlePartsTheme.Dark}
      size={getSize(isXl, isMd, shrink)}
      position={ETitlePartsPosition.Left}
    >
      {title}
    </TitlePart>
  );
};

export default Title;
