export enum EBannerCardSpaceType {
  Image = 'Image',
  Text = 'Text',
}

export enum EBannerCardBgV2Theme {
  Theme1 = 'Theme1',
  Theme2 = 'Theme2',
}

export enum EBannerCardsV2AlignImage {
  Left = 'Left',
  Right = 'Right',
}

export enum EBannerCardsV2HideSpaceOnMobile {
  Yes = 'Yes',
  No = 'No',
}
