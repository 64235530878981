import clsx from 'clsx';
import React from 'react';

import { ImageWithPlaceholder } from '../../../atoms/ImageWithPlaceholder';
import { TBannerCardBgV2Props } from '../types';

const Image: React.FC<
  Pick<TBannerCardBgV2Props, 'image'> & {
    onLoad?: (
      e: React.SyntheticEvent<HTMLImageElement, Event> & {
        target: HTMLImageElement;
      },
    ) => void;
  }
> = props => {
  const { image, onLoad } = props;

  if (!image) {
    return null;
  }

  return (
    <div
      className={clsx(
        'flex w-full items-center justify-center gap-4 xl:max-w-full xl:rounded-3xl xl:pb-0',
      )}
    >
      <ImageWithPlaceholder
        {...image}
        onLoad={onLoad}
        className='size-full object-contain'
      />
    </div>
  );
};

export default Image;
