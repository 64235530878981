import React from 'react';

import BlockTitle from './BlockTitle';
import { Icon } from '../../../atoms/Icon';
import { EIconSize } from '../../../atoms/Icon/Icon.constants';
import { TBannerCardBgV2Block } from '../types';

type TBannerCardBgV2BlockProps = TBannerCardBgV2Block;

const Block: React.FC<TBannerCardBgV2BlockProps> = props => {
  const { icon, description, title } = props;

  return (
    <div className='flex max-w-[22.5rem] flex-col gap-4'>
      {icon && <Icon icon={icon} size={EIconSize.Xl} />}

      <BlockTitle title={title} />

      {description && (
        <div className='inline-flex items-start justify-start gap-2 pe-2 text-base leading-mega-loose text-interface-700 empty:hidden'>
          {description}
        </div>
      )}
    </div>
  );
};

export default Block;
