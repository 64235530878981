import { baseTailwindConfig } from '@front/shared';
import { motion, useScroll } from 'framer-motion';
import React, { useRef } from 'react';
import { useMedia } from 'react-use';

import { EBannerCardsBgV2Animation } from './BannerCardsBgV2.constants';
import Container from '../../sections/Container/Container';
import {
  BannerCardBgV2,
  EBannerCardsV2AlignImage,
  TBannerCardBgV2Props,
} from '../BannerCardBgV2';

export type TBannerCardsBgV2Props = {
  cards?:
    | Omit<
        TBannerCardBgV2Props,
        | 'idx'
        | 'isAnimationEnabled'
        | 'progress'
        | 'rangeOpacity'
        | 'rangeWidth'
        | 'rangeTitle'
        | 'targetMinWidth'
        | 'targetMaxWidth'
        | 'targetOpacity'
        | 'targetOffset'
      >[]
    | null;
  alignImage: EBannerCardsV2AlignImage;
  animation?: EBannerCardsBgV2Animation;
};

const STEP_OFFSET = 48;
const TARGET_OPACITY = 1;

function ContainerWrapper({
  isAnimationEnabled,
  children,
}: {
  isAnimationEnabled: boolean;
  children: React.ReactNode;
}): React.ReactNode {
  if (!isAnimationEnabled) {
    return children;
  }

  return (
    <motion.div
      style={{
        position: 'sticky',
        top: STEP_OFFSET * 3,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {children}
    </motion.div>
  );
}

const BannerCardsBgV2: React.FC<TBannerCardsBgV2Props> = ({
  cards,
  alignImage,
  animation,
}) => {
  const container = useRef(null);
  const isXlBreakpoint = useMedia(
    `(min-width: ${baseTailwindConfig.screens.xl})`,
    true,
  );
  const isXxlBreakpoint = useMedia(
    `(min-width: ${baseTailwindConfig.screens['2xl']})`,
    true,
  );

  const isAnimationEnabled =
    isXlBreakpoint && animation === EBannerCardsBgV2Animation.Enabled;

  const { scrollYProgress } = useScroll({
    target: container,
    offset: ['start start', 'end end'],
  });

  if (!cards?.length) {
    return null;
  }

  const count = cards.length;

  return (
    <section ref={container}>
      <Container>
        <div
          className='flex flex-col justify-center gap-4'
          style={{
            paddingBottom: isAnimationEnabled ? STEP_OFFSET * (count + 1) : 0,
          }}
        >
          {cards.map((card, index) => {
            const isLast = index === count - 1;

            let minWidth = isXxlBreakpoint ? 1040 : 800;
            const maxWidth = isXxlBreakpoint ? 1232 : 992;
            const offset = STEP_OFFSET * index;

            if (!isLast) {
              minWidth = minWidth + 48 * index;
            } else {
              minWidth = maxWidth;
            }

            return (
              <ContainerWrapper
                key={index}
                isAnimationEnabled={isAnimationEnabled}
              >
                <BannerCardBgV2
                  {...card}
                  idx={index}
                  isAnimationEnabled={isAnimationEnabled}
                  alignImage={alignImage}
                  targetMinWidth={minWidth}
                  targetMaxWidth={maxWidth}
                  targetOpacity={TARGET_OPACITY}
                  targetOffset={offset}
                  progress={scrollYProgress}
                  rangeOpacity={[
                    (index + 2) * (1 / count),
                    (index + 3) * (1 / count),
                  ]}
                  rangeWidth={[
                    index * (1 / (count - 1)),
                    (index + 1) * (1 / (count - 1)),
                  ]}
                  rangeTitle={[
                    (index + 1) * (1 / count),
                    (index + 2) * (1 / count),
                  ]}
                />
              </ContainerWrapper>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default BannerCardsBgV2;
