import {
  BannerCardsBgV2,
  RichText,
  toImageProps,
  isRichTextContentEmpty,
  EBannerCardsV2AlignImage,
  EBannerCardBgV2Theme,
  EBannerCardSpaceType,
  EBannerCardsV2HideSpaceOnMobile,
} from '@front/shared/ds';
import { Section } from '@shared/master-types';
import { EBannerCardsBgV2Animation } from 'front/shared/ds/src/components/rows/BannerCardsBgV2/BannerCardsBgV2.constants';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';

import { useAppDispatch } from '../../../store/store.hooks';
import toCardButtons from '../../../utils/toCardButtons';

export type TBannerCardsBgV2ConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'banner-cards-bg-v2' }
>;

const BannerCardsBgV2Connected: React.FC<TBannerCardsBgV2ConnectedProps> = ({
  cards,
  alignImage,
  animation,
}) => {
  const router = useRouter();
  const appDispatch = useAppDispatch();
  const currentLocale = String(router.query.locale || '');

  const bannerCards = useMemo(
    () =>
      cards?.map(
        ({
          blocks = [],
          theme,
          buttons,
          eyebrows,
          image,
          description,
          title,
          space,
          iconTop,
          id,
          hideSpaceOnMobile,
        }) => ({
          id,
          buttons: toCardButtons(buttons, currentLocale, appDispatch),
          eyebrows: eyebrows?.map(({ eyebrow }) => eyebrow),
          image: toImageProps(image),
          description:
            description && !isRichTextContentEmpty(description) ? (
              <RichText content={description} />
            ) : null,
          title:
            title && !isRichTextContentEmpty(title) ? (
              <RichText content={title} />
            ) : null,
          space: space?.map(block => ({
            image: toImageProps(block.image),
            type: block.type as EBannerCardSpaceType,
            text:
              block.text && !isRichTextContentEmpty(block.text) ? (
                <RichText content={block.text} />
              ) : null,
          })),
          icon: toImageProps(iconTop),
          blocks: blocks?.map(block => ({
            icon: toImageProps(block.icon),
            title: block.title,
            description:
              block.description &&
              !isRichTextContentEmpty(block.description) ? (
                <RichText content={block.description} />
              ) : null,
          })),
          theme: theme as EBannerCardBgV2Theme,
          alignImage: alignImage as EBannerCardsV2AlignImage,
          hideSpaceOnMobile:
            hideSpaceOnMobile === EBannerCardsV2HideSpaceOnMobile.Yes,
        }),
      ),
    [cards, currentLocale, appDispatch, alignImage],
  );

  return (
    <BannerCardsBgV2
      cards={bannerCards}
      alignImage={alignImage as EBannerCardsV2AlignImage}
      animation={animation as EBannerCardsBgV2Animation}
    />
  );
};

export default BannerCardsBgV2Connected;
